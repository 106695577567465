import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { ImagesService } from 'src/app/services/images/images.service';
import { ToastrService } from 'ngx-toastr';
import { FilesService } from 'src/app/services/files/files.service';
// import { CmsCoachesService } from 'src/app/services/CMS/cms-coaches.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-file-input-uploader',
  templateUrl: './file-input-uploader.component.html',
  styleUrls: ['./file-input-uploader.component.css']
})
export class FileInputUploaderComponent implements OnInit {
 

  @ViewChild("hiddenInputsContainer",{static:true}) hiddenInputsContainer: ElementRef;
  @Input() NoFilesText: string = "No Files Uploaded";
  @Input() BoundFile: UploadedFile;
  @Output() FileUploaded: EventEmitter<UploadedFile> = new EventEmitter<UploadedFile>();
  @Input() UploadedFileType: string;
  @Input() IsCMSControled = false ;

  constructor(private imagesService: ImagesService,
    private filesService: FilesService,
    private ToastrService: ToastrService,
    private translate:TranslateService, ) { }

  ngOnInit() {
  }

  public async addFileClicked(event) {
    let input = document.createElement("input");
    let type = document.createAttribute("type");
    type.value = "file";

    input.setAttributeNode(type);

    this.hiddenInputsContainer.nativeElement.appendChild(input);

    input.onchange = async () => {
      if (input.value) {
        await this.UploadFile(input);
      }
    }

    input.click();

  }



  public HasBoundFile() {
    return this.BoundFile && this.BoundFile.UploadedFileId && this.BoundFile.UploadedFileSource;
  }

  public async UploadFile(input) {
    let form = new FormData();
    let response;
    switch (this.UploadedFileType) {
      case "resume": {
        form.append("resume", input.files[0]);
        if (!this.IsCMSControled){
          response = await this.filesService.UploadResume(form).toPromise();
        }
        else { 
          // response = await this.cmsService.UploadResume(form).toPromise();
        } 
      }
    }


    if (!response.error) {
      this.BoundFile = {
        Input : input,
        UploadedFileSource : response.result.url,
        UploadedFileId : response.result.id
      }
      this.FileUploaded.emit({
        UploadedFileId: response.result.id,
        UploadedFileSource: response.result.url
      });
    }
    else {
      this.ToastrService.error(this.translate.currentLang=="ar"?"رفع الصور غير ممكن":"Unable to upload Image");
    }
  }


  public removeFile(event, index) {
    this.BoundFile = null;
    let inputs = this.hiddenInputsContainer.nativeElement.querySelectorAll("input");
    inputs.forEach(element => {
      this.hiddenInputsContainer.nativeElement.removeChild(element);
    });

  }


  public filenameOnly(path: string) {
    return path.split(/(\\|\/)/g).pop();
  }




}


export interface UploadedFile {
  UploadedFileSource: string,
  UploadedFileId: Number,
  Input?: HTMLInputElement
}
