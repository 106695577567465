import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-sticky-notifications-bar",
  templateUrl: "./sticky-notifications-bar.component.html",
  styleUrls: ["./sticky-notifications-bar.component.css"]
})
export class StickyNotificationsBarComponent implements OnInit {
  @Input() NotificationTemplate: TemplateRef<any>;
  public IsClosed = false;
  public static OnlyInstanceAccessor: StickyNotificationsBarComponent;

  constructor(private _activeRoute: ActivatedRoute) {
    StickyNotificationsBarComponent.OnlyInstanceAccessor = this;
  }

  ngOnInit() {}

  public Open() {
    this.IsClosed = false;
  }

  public Close() {
    this.IsClosed = true;
  }

  public static Hide() {
    if (StickyNotificationsBarComponent.OnlyInstanceAccessor) {
      StickyNotificationsBarComponent.OnlyInstanceAccessor.Close();
    }
  }
}
