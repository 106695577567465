import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/global_services/authentication.service';
import { SessionService } from '../services/global_services/session.service';
import { ClaimRoles } from '../common/enums';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AnyAuthenticationGuard implements CanActivate  {
  path: ActivatedRouteSnapshot[]; route: ActivatedRouteSnapshot;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly sessionService: SessionService,
    private readonly router: Router,
    private toastService: ToastrService
  ) { }

  canActivate(): boolean {
    let isLogged = this.authenticationService.isLoggedUser();
    let userSession = this.sessionService.GetUserData();
    if (isLogged && userSession) {
      return true;
    }
    this.toastService.error("You are no Logged In", "Login Failed");
    this.router.navigate(['login']);
    return false;
  }
}
