import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MiscService {

  tokenToChangPassword: string = 'NO_Token_Yet';

  // token = new HttpHeaders()
  
  constructor(private http: HttpClient) {}

  public Getspecialities() {
    let targetUrl = `${environment.baseUrl}management/Tags`;
    return this.http.get<any>(targetUrl);
  }

  public GetUsersQuotes() {
    let targetUrl = `${environment.api_base}/public/misc/get_users_quotes`;
    return this.http.get<any>(targetUrl);
  }

  public SendContactMessage(data) {
    let targetUrl = `${environment.api_base}/public/misc/send_contact_email`;
    return this.http.post<any>(targetUrl, data);
  }

  public SubmitAccountReset(data) {
    let targetUrl = `${environment.api_base}/public/accounts/submit_account_new_password`;
    return this.http.post<any>(targetUrl, data);
  }

  public SubmitForgettenEmail(email) {
    let targetUrl = `${environment.baseUrl}Account/forgetPassword/${email}`;
    return this.http.post<any>(targetUrl, null);
  }

  public sendVerificationCode(email) {
    let targetUrl = `${environment.baseUrl}Account/resendVerificationCode/${email}`;
    return this.http.post<any>(targetUrl, null);
  }

 
  getVerificationCode(id) {
    let targetUrl = `${environment.baseUrl}Account/getVerificationCode/${id}`;
    return this.http.get<any>(targetUrl);
  }

  setNewPassword(password: FormData) { 
    let targetUrl = `${environment.baseUrl}Account/newPassword`;
    return this.http.post<any>(targetUrl, password, { headers: {
      Authorization: `bearer ${this.tokenToChangPassword}`
      ,"Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
    } });
  }


}
