import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-coach-confirmation-done",
  templateUrl: "./coach-confirmation-done.component.html",
  styleUrls: ["./coach-confirmation-done.component.css"],
})
export class CoachConfirmationDoneComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
