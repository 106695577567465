import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  ViewEncapsulation
} from "@angular/core";
import { ImagesService } from "src/app/services/images/images.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "src/app/services/translation.service";

@Component({
  selector: "app-video-upload-field",
  templateUrl: "./video-upload-field.component.html",
  styleUrls: ["./video-upload-field.component.css"]
})
export class VideoUploadFieldComponent implements OnInit {
  @ViewChild("hiddenInputsContainer", { static: true })
  hiddenInputsContainer: ElementRef;
  @Input() NoFilesText: string = "No Files Uploaded";
  @Input() BoundFile: HTMLInputElement;
  @Input() IsCMSControl: boolean = false;
  @Input() UploadedVideoSource: string = null;
  @Input() UploadedVideoId: Number = null;
  @Output() VideoUploaded: EventEmitter<UploadedVideo> = new EventEmitter<
    UploadedVideo
  >();

  constructor(
    private imagesService: ImagesService,
    private _tranalationService: TranslationService,
    private ToastrService: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit() { }

  public async addFileClicked(event) {
    let input = document.createElement("input");
    let type = document.createAttribute("type");
    let accept = document.createAttribute("accept");
    accept.value = "video/*";
    type.value = "file";

    input.setAttributeNode(type);
    input.setAttributeNode(accept);

    this.hiddenInputsContainer.nativeElement.appendChild(input);

    input.onchange = async () => {
      if (input.value) {
        this.BoundFile = input;
        await this.uploadVideo();
      }
    };

    input.click();
  }

  isArabic() {
    var lang = this._tranalationService.getLanguage();
    if (lang == "ar") return true;

    return false;
  }
  public HasBoundVideo() {
    return this.UploadedVideoId && this.UploadedVideoSource;
  }

  public async uploadVideo() {
    let form = new FormData();
    form.append("image", this.BoundFile.files[0]);
    let response = null;
    if (!this.IsCMSControl) {
      response = await this.imagesService.UploadImage(form).toPromise();
    } else {
      response = await this.imagesService.UploadCMSImage(form).toPromise();
    }
    console.log(response);
    if (!response.error) {
      this.UploadedVideoSource = response.result.url;
      this.UploadedVideoId = response.result.added_image_id;
      this.VideoUploaded.emit({
        UploadedVideoId: response.result.added_image_id,
        UploadedVideoUrl: response.result.url
      });
    } else {
      this.ToastrService.error(
        this.translate.currentLang == "ar"
          ? "رفع الصور غير ممكن"
          : "Unable to upload Video"
      );
    }
  }

  public removeFile(event, index) {
    this.BoundFile = null;
    this.UploadedVideoSource = null;
    let inputs = this.hiddenInputsContainer.nativeElement.querySelectorAll(
      "input"
    );
    inputs.forEach(element => {
      this.hiddenInputsContainer.nativeElement.removeChild(element);
    });
  }

  public filenameOnly(path: string) {
    return path.split(/(\\|\/)/g).pop();
  }
}

export interface UploadedVideo {
  UploadedVideoUrl: string;
  UploadedVideoId: Number;
}
