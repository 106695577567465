import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GlobalEventsService } from 'src/app/services/global_services/global-events.service';
import { SessionService } from 'src/app/services/global_services/session.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-register-confirmation-required',
  templateUrl: './register-confirmation-required.component.html',
  styleUrls: ['./register-confirmation-required.component.css']
})
export class RegisterConfirmationRequiredComponent implements OnInit {

  public email: string;
  public userId: string;
  public registerType: string;

  private currentUserSubject: BehaviorSubject<any>;
  
  constructor(
    private activeRoute: ActivatedRoute, 
    private titleService: Title,
    private router: Router,
    private toastrService: ToastrService,
    private sessionService: SessionService, 
    private route: Router, 
    private http: HttpClient) {
      this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      this.email = params["email"];
      this.userId = params["userId"];
      this.registerType = params["registerType"];
    }) 
  }
 
    onCodeChanged(code: string) { 
    }
     
    onCodeCompleted(code: string) { 
      if (this.registerType == "Coach") { 
        
        let reqPayload = new FormData();
        reqPayload.append("couchRequest", this.userId);
        reqPayload.append("VerificationCode", code);
        
       this.http.post<any>(environment.baseUrl + "Account/verifyCoach", reqPayload).subscribe(res => {
         if (res.errorCode == 0) {
           this.toastrService.success("Verified Successfully");
           this.route.navigate(['/login'])
          } else if(res.errorCode == 7) {
           this.toastrService.error("Verification code is incorrect");
          }
        }, error => {
         this.toastrService.error("Something has went wrong."); 
       })
      } 

      if (this.registerType == "User") {
        let reqPayload = new FormData();
        reqPayload.append("UserId", this.userId);
        reqPayload.append("VerificationCode", code);
        
       this.http.post<any>(environment.baseUrl + "Account/verify", reqPayload).subscribe((res: any) => {
         if (res.errorCode == 0) {
           
           this.sessionService.SaveUserData(res.data); 
           GlobalEventsService.UserLoggedIn.emit(res.data);
           this.currentUserSubject.next(res.data);  
           this.toastrService.success("Verified Successfully"); 
           this.router.navigate(['/assessment-form']).then(() => {
             location.reload(); 
           });  
           
          } 
        }, error => {
         this.toastrService.error("Something has went wrong"); 
       })
      }
       
      


    }
    resendTheCode() { 
      this.http.post<any>(environment.baseUrl + "Account/resendVerificationCode/" + this.email, null).subscribe(res => {
        if (res.errorCode == 0) {
          this.toastrService.success("Check your Email now");
         } else if(res.errorCode == 7) {
          this.toastrService.error("Error");
         }
       }, error => {
        this.toastrService.error("Something has went wrong.");
 
      })
 
    }

}
