import { Directive, Output, EventEmitter, HostListener, ElementRef, OnInit, OnDestroy } from '@angular/core';
declare var $: any;

@Directive({
  selector: '[BottomReached]'
})
export class BottomReachedDirective implements OnInit, OnDestroy {




  @Output() BottomReached: EventEmitter<any> = new EventEmitter();


  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }


  constructor(private elmRef: ElementRef) { }




  scroll = (event: any): void => {
    var elem = $(this.elmRef.nativeElement);
    if ($(window).scrollTop() >= $(elem).offset().top + $(elem).outerHeight() - window.innerHeight) {
      this.BottomReached.emit();
    }
  };





  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true); //third parameter
  }


}
