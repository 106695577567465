import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-payment-card",
  templateUrl: "./payment-card.component.html",
  styleUrls: ["./payment-card.component.css"]
})
export class PaymentCardComponent implements OnInit {
  @Input("Title") public Title = "";
  @Input("Price") public Price = "";
  @Output("OnFormSubmitted") public OnFormSubmitted: EventEmitter<
    any
  > = new EventEmitter<any>();
  public CreditCardInfo = {
    card_name: "",
    card_number: "",
    expiration_date: ""
  };

  constructor() {}

  ngOnInit() {}

  public SumbitClicked() {
    this.OnFormSubmitted.emit(this.CreditCardInfo);
  }
}
