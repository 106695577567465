import { Component, OnInit } from '@angular/core';
import { AssessmentService } from './assessment.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'src/app/services/global_services/session.service';


export enum QuestionType {
  Date = 1,
  Phone = 3,
  TextArea = 4,
  MultiSelection = 5,
  SingleSelection = 6,
  BookingDate = 7,
  Duration = 8,
  Checkbox = 9,
  Number = 10,
  Name = 11,
}

export class payload {
  answerId: number[];
  questionId: number
} 


@Component({
  selector: 'app-assessment-form',
  templateUrl: './assessment-form.component.html',
  styleUrls: ['./assessment-form.component.css']
})
export class AssessmentFormComponent implements OnInit {

  Questions = []
  submitted
  QuestionType = QuestionType;
  Answers = [];
  Mode: "Create" | "Update" = "Create"
  

  constructor(
    private assessmentServive: AssessmentService,
    private router: Router,
    private session: SessionService,
    private toast: ToastrService,
    ) { }

  ngOnInit() {
    this.assessmentSearch(); 
    this.Mode = !this.session.GetUserData().assessment ? "Create" : "Update";  
  }

  assessmentSearch = () => {
    this.assessmentServive.assessmentSearch().subscribe(res => {
      if (res.errorCode == 0) {
        this.Questions = res.data;  
        this.Questions.forEach(q => {   
          
          if (q.questionType == QuestionType.MultiSelection) {
            q.answers[0].userAnswer = q.answers.map(s => s.userAnswer && s.userAnswer.length ? s.id : null);
            q.answers[0].userAnswer = q.answers[0].userAnswer.filter(item => item !== null); 
            this.onChoose(q.id, q.answers[0].userAnswer) 
          } 


          else if (q.questionType == QuestionType.SingleSelection) { 
            q.answers[0].userAnswer = q.answers.map(s => s.userAnswer && s.userAnswer.length ? s.id : null);
            q.answers[0].userAnswer = q.answers[0].userAnswer.filter(item => item !== null);
            this.onChoose(q.id, q.answers[0].userAnswer)  
          } 
           
        });  
      }
    })
  }

  submit = () => {  

    this.Answers = this.Answers.map(data => {
      if (!data.answerId) {
        data.answerId = [];
        return data;
      } 
      if (!data.answerId.length) {
        data.answerId = [];
        return data; 
      }
      if (data.answerId[0] == null) {
        data.answerId = [];
        return data;
      }
      return data
    });
    

    if (this.Mode == "Create") {
      this.assessmentServive.assessmentCreate({data: this.Answers}).subscribe(res => { 
        if (res.errorCode == 0) { 
          this.toast.success("Assessment Completed Successfully") 
          let userData = this.session.GetUserData();
          userData.assessment = true;
          localStorage.setItem("UserData", JSON.stringify(userData))
          this.router.navigate(['/']).then(()=> location.reload());
        } else  
          this.toast.error("Make Sure You Filled all required Fields"); 
        })  
      } else { 
        this.assessmentServive.assessmentUpdate({data: this.Answers}).subscribe(res => { 
          if (res.errorCode == 0) { 
            this.toast.success("Assessment Updated Successfully")
            this.router.navigate(['/']);
          } else 
          this.toast.error("Make Sure You Filled all required Fields");  
      }) 
    }
  }
  
  routeToDashBoard(){
    this.router.navigate(["user/dashboard"]);
  }

  onChoose = (questionId, answerId) => {     
 
    if (!answerId) answerId = []
    if (!answerId.length) answerId = []
    // if (answerId[0] == null) {} answerId = []
    
    let handlePayload: payload = { questionId, answerId }  
    
    let answerIndex = this.Answers.findIndex(q => q.questionId == questionId); 
    if (answerIndex > -1) { 
      this.Answers[answerIndex] = handlePayload 
    } else { 
      this.Answers.push(handlePayload);  
    }    
  }  

} 
