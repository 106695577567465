import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Pagenation } from 'src/app/common/pagenation';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsAPIService {

  constructor(private http: HttpClient) { }



  public GetUserNotifications(paging: Pagenation = null) {
    return
    let query = "";
    if (typeof paging.page != "undefined" && typeof paging.rows !== "undefined") {
      query = `?page=${paging.page}&page_size=${paging.rows}`
    }
    return
    // let targetUrl = `${environment.api_base}/private/notifications/get_notifications${query}`;
    let targetUrl = `${environment.baseUrl}Notification`;
    return this.http.get<any>(targetUrl);
  }

  public SetAsRead(notificationsIdsArray: Array<Number>) {
    return

    let targetUrl = `${environment.api_base}/private/notifications/set_notifications_as_read`;
    return this.http.post<any>(targetUrl, { notifications : notificationsIdsArray });
  }



}
