import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient) { }

  public UploadResume(resumeFormData: FormData) {
    
    let targetUrl = `${environment.api_base}/private/resumes/upload_new`
    return this.http.post<any>(targetUrl, resumeFormData);
  }

  
}
