import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MiscService } from "src/app/services/misc.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"]
})
export class ResetPasswordComponent implements OnInit {
  ResetPasswordForm: FormGroup;
  submitted = false;
  message: string = "";

  public RestorationSubmitionModel = {
    rs: null,
    claim_id: null,
    claim_role: null
  };

  constructor(
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastService: ToastrService,
    private miscService: MiscService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      this.RestorationSubmitionModel.rs = params["rs"];
      this.RestorationSubmitionModel.claim_id = params["claim_id"];
      this.RestorationSubmitionModel.claim_role = params["claim_role"];
    });

    this.ResetPasswordForm = this.formBuilder.group({
      password: ["", Validators.required],
      confirm_password: ["", Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.ResetPasswordForm.controls;
  }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.ResetPasswordForm.invalid) {
      return;
    }

    // check password confirm
    if (
      this.ResetPasswordForm.controls.password.value !=
      this.ResetPasswordForm.controls.confirm_password.value
    ) {
      this.toastService.error(
        this.translate.currentLang == "ar"
          ? "كلمة المرور والتأكيد لا يتطابقان"
          : "Password And Confirmation Does Not Match"
      );
      return;
    }
 
    if (this.miscService.tokenToChangPassword == "NO_Token_Yet") {
      this.toastService.error("You have to verify You account firstly");
      return;
    }
    
    
    let request = new FormData(); 
    request.append("password", this.ResetPasswordForm.controls.password.value);

    let response = null;
    try {
      response = await this.miscService
        .setNewPassword(request)
        .toPromise();
      if (response.error) {
        this.toastService.error("something went wrong while processing data"); 
      } else {
        this.toastService.success("password updated successfully");
        this.router.navigate(["/login"]);
      }
    } catch (err) {
      this.toastService.error("something went wrong while processing data"); 
    }
  }
}
