import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CoachBookingStatus } from "src/app/common/enums";
import { Pagenation } from "src/app/common/pagenation";
@Injectable({
  providedIn: "root",
})
export class RequestsService {
  constructor(private http: HttpClient) {}

  public GetRequests() {
    let targetUrl = `${environment.api_base}/private/requests/all`;
    return this.http.get<any>(targetUrl);
  }

  public GetRequestsByStatus(status: CoachBookingStatus) {
    let targetUrl = `${environment.api_base}/private/requests/all?type=${status}`;
    return this.http.get<any>(targetUrl);
  }

  public ConfirmBooking(bookingId) {
    let targetUrl = `${environment.api_base}/private/requests/confirm_request`;
    return this.http.post<any>(targetUrl, { booking_id: bookingId });
  }

  public CancelBooking(req) { 
    let targetUrl = `${environment.baseUrl}Assignment/reject`;
    return this.http.post<any>(targetUrl, req);
  }

  public GetUserRequests(paging: Pagenation = null) { 


    let req = {    
      statuses: [
        1, 2, 3, 4, 5, 12 //check for status 
      ],
      pageSize: paging.page,
      pageNumber: paging.rows
    }

    let client_time = new Date().toISOString();
    let targetUrl = `${environment.baseUrl}Appointment/search`;
    return this.http.post<any>(targetUrl, req);
  }

  public GetUserRequestsForUpComing(paging: Pagenation = null) { 

    let now = new Date().toISOString()

    let req = {    
      statuses: [
        1, 2, 3, 4, 5, 12 //check for status 
      ],
      pageSize: paging.page,
      pageNumber: paging.rows,
      from : now
    }

    let client_time = new Date().toISOString();
    let targetUrl = `${environment.baseUrl}Appointment/search`;
    return this.http.post<any>(targetUrl, req);
  }

  public GetUserHistoryRequests(paging: any = null) { 
    let req = {    
      statuses: [
         6, 7, 8 ,9,11
    
      ],
      pageSize: paging.page,
      pageNumber: paging.rows
    }
    let targetUrl = `${environment.baseUrl}Appointment/search`;
    return this.http.post<any>(targetUrl, req);
  }

  public GetUserCoursesBooking(paging: Pagenation = null) {
    let query = "";
    if (
      typeof paging.page != "undefined" &&
      typeof paging.rows !== "undefined"
    ) {
      query = `?page=${paging.page}&page_size=${paging.rows}`;
    }

    let client_time = new Date().toISOString();
    let targetUrl = `${environment.api_base}/private/requests/get_user_courses_booking${query}&client_time=${client_time}`;
    return this.http.get<any>(targetUrl);
  }

  public GetUserHistoryCoursesBooking(paging: Pagenation = null) {
    let query = "";
    if (
      typeof paging.page != "undefined" &&
      typeof paging.rows !== "undefined"
    ) {
      query = `?page=${paging.page}&page_size=${paging.rows}`;
    }

    let client_time = new Date().toISOString();
    let targetUrl = `${environment.api_base}/private/requests/get_user_history_courses_booking${query}&client_time=${client_time}`;
    return this.http.get<any>(targetUrl);
  }

  public UserCancelBooking(id) {
    let targetUrl = `${environment.api_base}/private/requests/user_cancel_request`;
    return this.http.post<any>(targetUrl, { booking_id: id });
  }

  public GetBookingById(id) {
    let targetUrl = `${environment.baseUrl}Appointment/${id}`;
    return this.http.get<any>(targetUrl);
  }

  public GetBookingByIdForCoach(id) {
    let targetUrl = `${environment.baseUrl}Coach/Appointment/${id}`;
    return this.http.get<any>(targetUrl);
  }

  public GetAllUserRequests() {
    let client_time = new Date().toISOString();
    let targetUrl = `${environment.api_base}/private/requests/get_all_user_requests?client_time=${client_time}`;
    return this.http.get<any>(targetUrl);
  }

  public GetAllCoachRequests() {
    let client_time = new Date().toISOString();
    let targetUrl = `${environment.api_base}/private/requests/get_all_coach_requests?client_time=${client_time}`;
    return this.http.get<any>(targetUrl);
  }

  public cancelSessionForUser(id) {
    let targetUrl = `${environment.baseUrl}Appointment/cancel/${id}`;
    return this.http.post<any>(targetUrl,null);
  }

  public cancelSessionForCoach(id) {
    let targetUrl = `${environment.baseUrl}Coach/Appointment/cancel/${id}`;
    return this.http.post<any>(targetUrl,null);
  }



}
