import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {


  constructor(private http: HttpClient) { }

  public UploadImage(imgFormData: FormData) {
    
    let targetUrl = `${environment.api_base}/private/images/upload_new`
    return this.http.post<any>(targetUrl, imgFormData);
  }

  public UploadCMSImage(imgFormData: FormData) {
    let targetUrl = `${environment.api_base}/cms/images/upload_new`
    return this.http.post<any>(targetUrl, imgFormData);
  }

}
