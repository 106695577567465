import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root"    
})
export class ChangePasswordService {

    constructor(private http: HttpClient) {}

    changePassword = (req: FormData) => this.http.patch<any>(environment.baseUrl + "Account/update", req);
    
}