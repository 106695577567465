import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UpperBorderComponent } from "./SharedComponents/upper-border/upper-border.component";
import { RateBoxComponent } from "./SharedComponents/rate-box/rate-box.component";
import { FormsModule } from "@angular/forms";
import { ExpandablePanelComponent } from "./SharedComponents/expandable-panel/expandable-panel.component";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { ImageUploadFieldComponent } from "./SharedComponents/image-upload-field/image-upload-field.component";
import { VideoUploadFieldComponent } from "./SharedComponents/video-upload-field/video-upload-field.component";
import { MultipleInputFieldComponent } from "./SharedComponents/multiple-input-field/multiple-input-field.component";
import { MultipleFileUploaderComponent } from "./SharedComponents/multiple-file-uploader/multiple-file-uploader.component";
import { FileInputUploaderComponent } from "./SharedComponents/file-input-uploader/file-input-uploader.component";
import { UpperTinyBorderComponent } from "./SharedComponents/upper-tiny-border/upper-tiny-border.component";
import { ExceprtPipe } from "./pipes/Excerpt";
import { NewIncomingCallComponent } from "./SharedComponents/new-incoming-call/new-incoming-call.component";
import { OverlayedBoxComponent } from "./SharedComponents/overlayed-box/overlayed-box.component";
import { CourseCardComponent } from "./SharedComponents/course-card/course-card.component";
import { PagingControlComponent } from "./SharedComponents/paging-control/paging-control.component";
import { DateRangeComponent } from "./SharedComponents/date-range/date-range.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BottomReachedDirective } from "./SharedDirectives/bottom-reached.directive";
import { PaymentCardComponent } from "./payment-card/payment-card.component";
import { TranslateModule } from "@ngx-translate/core";
import { GlobalMultipleFileUploaderComponent } from "../main_app_components/global-multiple-file-uploader/global-multiple-file-uploader.component";
import { ModalModule } from "ngx-bootstrap";

@NgModule({
  declarations: [
    UpperBorderComponent,
    RateBoxComponent,
    ExpandablePanelComponent,
    ImageUploadFieldComponent,
    VideoUploadFieldComponent,
    MultipleInputFieldComponent,
    MultipleFileUploaderComponent,
    FileInputUploaderComponent,
    UpperTinyBorderComponent,
    ExceprtPipe,
    NewIncomingCallComponent,
    OverlayedBoxComponent,
    CourseCardComponent,
    PagingControlComponent,
    PaymentCardComponent,
    DateRangeComponent,
    BottomReachedDirective,
    GlobalMultipleFileUploaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
  ],
  exports: [
    UpperBorderComponent,
    RateBoxComponent,
    ExpandablePanelComponent,
    ImageUploadFieldComponent,
    VideoUploadFieldComponent,
    MultipleInputFieldComponent,
    MultipleFileUploaderComponent,
    FileInputUploaderComponent,
    UpperTinyBorderComponent,
    ExceprtPipe,
    OverlayedBoxComponent,
    CourseCardComponent,
    PagingControlComponent,
    DateRangeComponent,
    BottomReachedDirective,
    PaymentCardComponent,
    GlobalMultipleFileUploaderComponent
  ]
})
export class SharedModule { }
