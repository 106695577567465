import * as moment from "moment";

export let DateHelpers = {
  MonthNames: [] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],

  isSameDateAs: function(firstDate, secondDate) {
    firstDate = new Date(firstDate);
    secondDate = new Date(secondDate);
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    );
  },
  Includes(datesArray, targetDate) {
    for (let i = 0; i < datesArray.length; i++) {
      if (this.isSameDateAs(datesArray[i], targetDate)) {
        return true;
      }
    }
    return false;
  },

  DateFormatted: function(date: Date) {
    return (
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
    );
  },

  TimeFormatted: function(date: Date) {
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  },

  IsMinutesClose(date, numberOfMinutes) {
    // make sure the booking date is valid for starting it's session
    // I.E the booking date_time is 10 minute before/after current time
    const minutes = numberOfMinutes * 60 * 1000;
    const bookingDateTime = new Date(date).getTime();
    const diffBetweenNowAndBookingDate = Date.now() - bookingDateTime;
    const diffOffset = Math.abs(diffBetweenNowAndBookingDate);
    return diffOffset < minutes;
  },

  // Parse an ISO date string (i.e. "2019-01-18T00:00:00.000Z",
  // "2019-01-17T17:00:00.000-07:00", or "2019-01-18T07:00:00.000+07:00",
  // which are the same time) and return a JavaScript Date object with the
  // value represented by the string.
  isoStringToDate: function(isoString): Date {
    // Split the string into an array based on the digit groups.
    var dateParts = isoString.split(/\D+/);

    // Set up a date object with the current time.
    var returnDate = new Date();

    // Manually parse the parts of the string and set each part for the
    // date. Note: Using the UTC versions of these functions is necessary
    // because we're manually adjusting for time zones stored in the
    // string.
    returnDate.setUTCFullYear(parseInt(dateParts[0]));

    // The month numbers are one "off" from what normal humans would expect
    // because January == 0.
    returnDate.setUTCMonth(parseInt(dateParts[1]) - 1);
    returnDate.setUTCDate(parseInt(dateParts[2]));

    // Set the time parts of the date object.
    returnDate.setUTCHours(parseInt(dateParts[3]));
    returnDate.setUTCMinutes(parseInt(dateParts[4]));
    returnDate.setUTCSeconds(parseInt(dateParts[5]));
    returnDate.setUTCMilliseconds(parseInt(dateParts[6]));

    // Track the number of hours we need to adjust the date by based
    // on the timezone.
    var timezoneOffsetHours = 0;

    // If there's a value for either the hours or minutes offset.
    if (dateParts[7] || dateParts[8]) {
      // Track the number of minutes we need to adjust the date by
      // based on the timezone.
      var timezoneOffsetMinutes = 0;

      // If there's a value for the minutes offset.
      if (dateParts[8]) {
        // Convert the minutes value into an hours value.
        timezoneOffsetMinutes = parseInt(dateParts[8]) / 60;
      }

      // Add the hours and minutes values to get the total offset in
      // hours.
      timezoneOffsetHours = parseInt(dateParts[7]) + timezoneOffsetMinutes;

      // If the sign for the timezone is a plus to indicate the
      // timezone is ahead of UTC time.
      if (isoString.substr(-6, 1) == "+") {
        // Make the offset negative since the hours will need to be
        // subtracted from the date.
        timezoneOffsetHours *= -1;
      }
    }

    // Get the current hours for the date and add the offset to get the
    // correct time adjusted for timezone.
    returnDate.setHours(returnDate.getHours() + timezoneOffsetHours);

    // eliminate timezone offset
    returnDate = new Date(
      returnDate.getTime() - returnDate.getTimezoneOffset() * -60000
    );

    // Return the Date object calculated from the string.
    return returnDate;
  },

  // to iso string without apply timezone diff
  ToISODate(date: Date) {
    return moment(date).format("YYYY-MM-DDTHH:mm:ss.000");
  },

  // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
  IsValidDate(dateString) {
    return moment(dateString, moment.ISO_8601, true).isValid();
  },

  FormatDateMonthShortented(date: Date) {
    try {
      return (
        this.__getMonthName(date).substr(0, 3) +
        " " +
        date.getDay() +
        "," +
        date.getFullYear()
      );
    } catch (err) {
      return "";
    }
  },

  __getMonthName: function(date: Date) {
    return this.MonthNames[date.getMonth()];
  }
};
