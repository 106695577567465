import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./main_app_components/login/login.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { RegisterUserComponent } from "./main_app_components/register-user/register-user.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { RegisterConfirmationRequiredComponent } from "./main_app_components/register-confirmation-required/register-confirmation-required.component";
import { RegisterCoachComponent } from "./main_app_components/register-coach/register-coach.component";
import { SelectDropDownModule } from "ngx-select-dropdown"; 
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { AuthenticationInterceptorService } from "./services/interceptors/authentication-interceptor.service"; 
import "./common/socketsIOConfig";
import { NotificationsService } from "./services/notifications/notifications.service";
import { SessionNotificationComponent } from "./main_app_components/notificationsComponents/session-notification/session-notification.component"; 
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule,
} from "ngx-ui-loader";
import { StickyNotificationsBarComponent } from "./main_app_components/sticky-notifications-bar/sticky-notifications-bar.component";
import { IncommingNotificationComponent } from "./main_app_components/incomming-notification/incomming-notification.component";
import { SessionCallBoxComponent } from "./main_app_components/session-call-box/session-call-box.component";
import { SharedModule } from "./sharedModule/shared.module";
 import { AboutusPageComponent } from "./pages/aboutus/aboutus-page/aboutus-page.component";
import { FAQMainPageComponent } from "./pages/FAQ/faqmain-page/faqmain-page.component";
import { NgwWowModule } from "ngx-wow";
import { WINDOW_PROVIDERS } from "./services/window.service";
import { DeviceDetectorModule } from "ngx-device-detector";
 import { NotFoundComponent } from "./main_app_components/not-found/not-found.component";
import { PasswordForgetEmailSentComponent } from "./main_app_components/password-forget-email-sent/password-forget-email-sent.component";
import { ResetPasswordComponent } from "./main_app_components/reset-password/reset-password.component";
import { UserConfirmationDoneComponent } from "./main_app_components/user-confirmation-done/user-confirmation-done.component";
import { CoachConfirmationDoneComponent } from './main_app_components/coach-confirmation-done/coach-confirmation-done.component';
import { ConfirmationErrorComponent } from './main_app_components/confirmation-error/confirmation-error.component'; 
import { environment } from "src/environments/environment";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslationService } from "./services/translation.service";
 import { TermsConditionsComponent } from "./pages/terms-conditions/terms-conditions.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { OwlModule } from 'ngx-owl-carousel';
import {MatSnackBarModule} from '@angular/material/snack-bar'; 
import { CodeInputModule } from 'angular-code-input'; 
 import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatSelectModule } from '@angular/material/select';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number-arabic';
import { NgxStarRatingModule } from "ngx-star-rating";
import { AssessmentFormComponent } from './main_app_components/assessment-form/assessment-form.component';
import { CorporatesComponent } from "./pages/corporates/corporates.component";
import { AlertModule } from "ngx-bootstrap";

 
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const ngxUiLoaderConfiguration = {
  bgsColor: "#785499",
  bgsOpacity: 0.5,
  bgsPosition: "bottom-right",
  bgsSize: 60,
  // bgsType: "ball-spin-clockwise",
  bgsType: "cube-grid",
  blur: 10,
  fgsColor: "#785499",
  fgsPosition: "center-center",
  fgsSize: 0, // WAS :: 60
  fgsType: "three-bounce",
  gap: 24,
  logoPosition: "center-center",
  logoSize: 120,
  logoUrl: "../assets/images/logo-after_8.gif",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(0, 0, 0, 0.8)",
  pbColor: "#785499",
  pbDirection: "ltr",
  pbThickness: 3,
  hasProgressBar: true,
  text: "",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  threshold: 500,
} as NgxUiLoaderConfig;

@NgModule({
  declarations: [
    
    AppComponent,
    LoginComponent,
    RegisterUserComponent,
    RegisterConfirmationRequiredComponent,
    RegisterCoachComponent,
   // GlobalMultipleFileUploaderComponent,
    SessionNotificationComponent,
    StickyNotificationsBarComponent,
    IncommingNotificationComponent,
    SessionCallBoxComponent,
    AboutusPageComponent,
    FAQMainPageComponent,
    NotFoundComponent,
    PasswordForgetEmailSentComponent,
    ResetPasswordComponent,
    UserConfirmationDoneComponent,
    CoachConfirmationDoneComponent,
    ConfirmationErrorComponent,
    TermsConditionsComponent,
    ChangePasswordComponent,
    AssessmentFormComponent, 
    CorporatesComponent, 
    
  ], 
  imports: [   
    CodeInputModule.forRoot({
      codeLength: 4,
      isCharsCode: true,
      code: ''
    }), 
    MatSnackBarModule,
    InternationalPhoneNumberModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule, 
    NgxStarRatingModule,
    FormsModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({ positionClass: "toast-bottom-right" }),
    SelectDropDownModule, 
    AlertModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfiguration),
    NgxUiLoaderRouterModule, // import this module for showing loader automatically when navigating between app routes
    NgxUiLoaderHttpModule.forRoot({
      exclude: [
        environment.api_base + "/private/requests/get_all_user_requests",
        environment.api_base + "/private/requests/get_all_coach_requests",
        environment.api_base + "/private/calendar/check_booking_status",
      ],
    }),
    SharedModule,
    NgwWowModule,
    DeviceDetectorModule.forRoot(),
    AccordionModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgSelectModule,
    OwlModule,
    MatSelectModule, 
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true,
    },
    // { provide: LocationStrategy, useClass: HashLocationStrategy } ,
    NotificationsService,
    WINDOW_PROVIDERS,
    TranslationService,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
