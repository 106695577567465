import { Component, OnInit, ElementRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-global-multiple-file-uploader',
  templateUrl: './global-multiple-file-uploader.component.html',
  styleUrls: ['./global-multiple-file-uploader.component.css']
})
export class GlobalMultipleFileUploaderComponent implements OnInit {

  @Input('NoFilesText') NoFilesText: string = "No Files Uploaded";
  @Input('BoundFiles') BoundFiles: Array<HTMLInputElement> = new Array<HTMLInputElement>();
  @ViewChild("hiddenInputsContainer",{static:true}) hiddenInputsContainer: ElementRef;
  @Output() FileSizeError = new EventEmitter();


  constructor(private _tranalationService:TranslationService) { }

  ngOnInit() {
  }



  public addFileClicked(event) {
    let input = document.createElement("input");
    let type = document.createAttribute("type");
    type.value = "file";

    input.setAttributeNode(type);

    this.hiddenInputsContainer.nativeElement.appendChild(input);

    input.onchange = () => {
      if (input.value) {
        let fileSize = input.files[0].size;
        // limit certificates to 10 mb
        if (fileSize > 10000000) {
          this.FileSizeError.emit(fileSize);
          return;
        }
        this.BoundFiles.push(input);
      }
    }

    input.click();

  }

  isArabic() {
    var lang = this._tranalationService.getLanguage();
    if (lang == 'ar')
      return true

    return false
  }
  public removeFile(event, index) {
    this.BoundFiles.splice(index, 1);
    let input = this.hiddenInputsContainer.nativeElement.querySelectorAll("input")[index];
    this.hiddenInputsContainer.nativeElement.removeChild(input);
  }


  public filenameOnly(path: string) {
    return path.split(/(\\|\/)/g).pop();
  }

}
