import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: "excerpt" })
export class ExceprtPipe implements PipeTransform {

    transform(htmlText: any, lengthToWrite: number) {
        if (htmlText === null) return null;

        let plainText = htmlText.replace(/<[^>]+>/g, '');

        let end = htmlText > lengthToWrite ? "..." : "";
        return plainText.substr(0, lengthToWrite) + end;
        
    }

}