import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Pagenation } from "src/app/common/pagenation";

@Injectable({
  providedIn: "root",
})
export class BlogsService {
  constructor(private http: HttpClient) { }

  public GetPinnedBlogs() {
    let targetUrl = `${environment.baseUrl}Blog?PageSize=2&PageNumber=1`;
    return this.http.get<any>(targetUrl, { headers: new HttpHeaders({ "Accept-language": localStorage.getItem('lang') }) });
  }

  public GetBlog(id: number) {
    // let targetUrl = `${environment.api_base}/public/blogs/get_blog?id=${id}`;
    let targetUrl = `${environment.baseUrl}Blog/${id}`;
    http://174.138.181.243/api/api/Blog/2
    return this.http.get<any>(targetUrl);
  }

  public GetBlogs(pagenation: Pagenation = null, tags: Array<Number> = null, searchTerm: string = null
  ) {
    let query = "?_=_";

    if (searchTerm) {
      query += `&search=${searchTerm}`;
    }

    if (pagenation) {
      if (
        typeof pagenation.rows !== "undefined" &&
        typeof pagenation.page !== "undefined"
      ) {
        query += `&page=${pagenation.page}&page_size=${pagenation.rows}`;
      }
    }

    if (tags && tags.length > 0) {
      query += `&tags=${tags.join(",")}`;
    }
    let targetUrl
    if (tags && tags.length > 0)
      targetUrl = `${environment.baseUrl}Blog?name=${searchTerm}&tags=${tags}&PageSize=${pagenation.rows}&PageNumber=${pagenation.page}`;
    else
      targetUrl = `${environment.baseUrl}Blog?name=${searchTerm}&PageSize=${pagenation.rows}&PageNumber=${pagenation.page}`;

    return this.http.get<any>(targetUrl);
  }

  public GetBlogTagsAvailable() {
    let targetUrl = `${environment.baseUrl}management/Tags`;
    return this.http.get<any>(targetUrl);
  }
}
