import { Component, OnInit, TemplateRef, Input } from '@angular/core';

@Component({
  selector: 'app-overlayed-box',
  templateUrl: './overlayed-box.component.html',
  styleUrls: ['./overlayed-box.component.css']
})
export class OverlayedBoxComponent implements OnInit {

  @Input("TemplateReference") TemplateReference: TemplateRef<any>;
  
  constructor() { }

  ngOnInit() {
    //this.TemplateReference.
  }

}
