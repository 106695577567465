
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../global_services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptorService  implements HttpInterceptor {


  constructor(private sessionService: SessionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
      let currentUser = this.sessionService.GetUserData();
      if (currentUser && currentUser.token) {
          request = request.clone({
              setHeaders: {
                  Authorization: `bearer ${currentUser.token}`,
                  "Accept-Language": `${localStorage.getItem("lang")}`
                  ,"Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone 
              }
          });
      } else {
        request = request.clone({
          setHeaders: { 
              "Accept-Language": `${localStorage.getItem("lang")}`
              ,"Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
          }
      });
      }

      return next.handle(request);
  }
}
