


import { Component, OnInit, ElementRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-multiple-file-uploader',
  templateUrl: './multiple-file-uploader.component.html',
  styleUrls: ['./multiple-file-uploader.component.css']
})
export class MultipleFileUploaderComponent implements OnInit {

  @Input('NoFilesText') NoFilesText: string = "No Files Uploaded";
  @Input('BoundFiles') BoundFiles: Array<MultipleFileUploaderBoundItem> = new Array<MultipleFileUploaderBoundItem>();
  @ViewChild("hiddenInputsContainer",{static:true}) hiddenInputsContainer: ElementRef;
  @Output() OnItemRemoved: EventEmitter<MultipleFileUploaderBoundItem> = new EventEmitter<MultipleFileUploaderBoundItem>();

  ItemName: string = "";


  constructor(private _tranalationService:TranslationService) { }

  ngOnInit() {
  }

  public addFileClicked(event) {
    if (this.ItemName.trim() === "") return;

    let input = document.createElement("input");
    let type = document.createAttribute("type");
    type.value = "file";

    input.setAttributeNode(type);

    this.hiddenInputsContainer.nativeElement.appendChild(input);

    input.onchange = () => {
      if (input.value) {
        let boundItem: MultipleFileUploaderBoundItem = {
          HTMLInputElement: input,
          ItemName: this.ItemName,
        }
        this.BoundFiles.push(boundItem);
        this.ItemName = ""
      }
    }

    input.click();

  }


  isArabic() {
    var lang = this._tranalationService.getLanguage();
    if (lang == 'ar')
      return true

    return false
  }
  public removeFile(event, index) {
    let spliced = this.BoundFiles.splice(index, 1);

    this.OnItemRemoved.emit(spliced[0]);

    let input = this.hiddenInputsContainer.nativeElement.querySelectorAll("input")[index];
    this.hiddenInputsContainer.nativeElement.removeChild(input);
  }


  public filenameOnly(path: string) {
    return path.split(/(\\|\/)/g).pop();
  }

}



export interface MultipleFileUploaderBoundItem {
  HTMLInputElement?: HTMLInputElement,
  ItemName: string,
  ItemId?: Number
}
