import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-upper-tiny-border',
  templateUrl: './upper-tiny-border.component.html',
  styleUrls: ['./upper-tiny-border.component.css']
})
export class UpperTinyBorderComponent implements OnInit {



  @Input("Title") Title: string;
  @Input("SubTitle") SubTitle: string;
  
  constructor() { }

  ngOnInit() {
  }
}
