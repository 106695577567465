import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MiscService } from 'src/app/services/misc.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-forget-email-sent',
  templateUrl: './password-forget-email-sent.component.html',
  styleUrls: ['./password-forget-email-sent.component.css']
})
export class PasswordForgetEmailSentComponent implements OnInit {

  public Email: string;
  public PasswordSubmitted = false;
  registerType
  UserId

  constructor(
    private activeRoute: ActivatedRoute,
    private toastService: ToastrService,
    private translate: TranslateService,
    private http: HttpClient,
    private route: Router,
    private miscService: MiscService) { }

  ngOnInit() {
  }


  async onSubmit() {
    if (this.Email === null || !this.Email || !this.validateEmail(this.Email)) {
      this.toastService.error(this.translate.currentLang == "ar" ? "البريد الألكتروني غير صالح " : "Email Is Not Valid");
      return;
    }
    else {
      let response = null;
      try {
        response = await this.miscService.SubmitForgettenEmail(this.Email).toPromise();
        if (response.errorCode == 0) {
          this.PasswordSubmitted = true;
          this.UserId = response.data;
        }
        else if (response.errorCode == 2) {
          this.toastService.error("Email not Found");
          return
        }
        else {
          this.toastService.error(this.translate.currentLang == "ar" ? "حصل خطأ أثناء معالجة البيانات" : "Something Went Wrong");
          console.log(response.error);
        }
      }
      catch (err) {
        this.toastService.error(this.translate.currentLang == "ar" ? "حصل خطأ أثناء معالجة البيانات " : "Something Went Wrong");
        console.log(err);
      }
    }
  } 


  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onCodeChanged(code: string) { 
  }
   
  onCodeCompleted(code: string) { 

    let reqPayload = new FormData();
    reqPayload.append("UserId", this.UserId);
    reqPayload.append("VerificationCode", code);
     
    
   this.http.post<any>(environment.baseUrl + "Account/verify", reqPayload).subscribe(res => {
     if (res.errorCode == 0) {
       this.toastService.success("Verified Successfully");
       this.miscService.tokenToChangPassword = res.data.token;
       this.route.navigate(['/reset_password']);
       
      } else if(res.errorCode == 7) {
       this.toastService.error("Verification code is incorrect");
      }
    }, error => {
     this.toastService.error("Something has went wrong."); 
   }) 

  }
  resendTheCode() {
    this.http.post<any>(environment.baseUrl + "Account/resendVerificationCode/" + this.Email, null).subscribe(res => {
      if (res.errorCode == 0) {
        this.toastService.success("Check your Email now");
       } else if(res.errorCode == 7) {
        this.toastService.error("Error");
       }
     }, error => {
      this.toastService.error("Something has went wrong.");

    })

  }
  
  
  
  
}
