import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, TemplateRef } from "@angular/core";
import * as moment from "moment";
import { _12HoursTo24BasedMapping } from "src/app/common/12HoursTo24Mappings";
import { SessionService } from "src/app/services/global_services/session.service";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "src/app/services/translation.service";
import { CalendarService } from "src/app/services/calendar/calendar.service";
import { SessionCheckerService } from "src/app/services/session-checker.service";
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CoachBookingStatus } from 'src/app/common/enums'; 

@Component({
  selector: "app-incomming-notification",
  templateUrl: "./incomming-notification.component.html",
  styleUrls: ["./incomming-notification.component.css"]
})
export class IncommingNotificationComponent implements OnInit {

  @Input() Slot: any;

  coachId: number;
  coacheeId: number;
  appointmentId: number;
  twilioToken
  CounterText = "";
  IsSessionInTime = false;
  roles: any[] = [];
  hide = false
  modalRef: BsModalRef;
  @ViewChild("rateTemplate", { static: false }) rateTemplate: TemplateRef<any>;


  rating: FormControl = new FormControl(0);
  comment: FormControl = new FormControl("");
  isSkipRateModal: boolean = false

  coachBookingStatus = CoachBookingStatus


  constructor(
    private session: SessionService,
    private translate: TranslateService,
    private readonly toast: ToastrService,
    private modalService: BsModalService,
    private _tranalationService: TranslationService,
    private calendarService: CalendarService,
    private _sessionChecker: SessionCheckerService,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private appCom: AppComponent
  ) {   
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(param => {
      this.appointmentId = param['session_id'];
      this.coachId = param['coach_id'];
    })
    console.log(this.Slot)
    this.Slot && this.Slot.length && this.loopSessionTimeChange(); 
  }

  ngOnChanges(): void {
    if (location.href.includes("communicate")) {
      this.Slot = [];  
      this.Slot = null; 
      // this.skip();  
    }  else { 
      this.Slot && this.Slot.length && this.loopSessionTimeChange(); 
    }
  }

  isArabic() {
    var lang = this._tranalationService.getLanguage();
    if (lang == "ar") return true;
    return false;
  }

  isCoach() {
    let userData = this.session.GetUserData();

    if (userData.roles)
      for (let index in userData.roles)
        this.roles.push(userData.roles[index].name);

    if (userData && this.roles.includes("Coach")) {
      return true;
    }

    return false;
  } 

  public loopSessionTimeChange() {
    if (!this.Slot) return
    let slotTime = moment(this.Slot[0].appointmentDate);

    let now = new Date();

    let diffMinutes = slotTime.diff(now, "minutes");

    if (Math.abs(diffMinutes) < 49) {
      this.IsSessionInTime = true;
      // this.CounterText = this._getCounterText(diffMinutes);
      this.CounterText = this._getCounterText(diffMinutes);
    } else {
      this.IsSessionInTime = false;
    }
  }

  private _getCounterText(minutesDifference) {
    let role = this.session.GetUserData();

    let absoluteMintes = Math.abs(minutesDifference);
    let firstName, lastName;
    let currentLang = this.translate.currentLang;


    // role === ClaimRoles.User 
    if (this.appCom.isUser.value) {
      if (this.Slot[0].coach) {
        firstName = this.Slot[0].coach.firstName;
        lastName = this.Slot[0].coach.lastName;
      }
    } else if (this.appCom.isCoach.value) {
      if (this.Slot[0].coachee) {
        firstName = this.Slot[0].coachee.firstName ? this.Slot[0].coachee.firstName : "";
        lastName = this.Slot[0].coachee.last_name ? this.Slot[0].coachee.lastName : "";
      }
    }

    if (minutesDifference > 0) {
      return `${currentLang == "ar" ? "الجلسة  " : "Your Session  "
        } ${firstName || ""} ${lastName || ""} ${currentLang == "ar" ? "سوف تبدأ بعد" : "Will Start After"
        } ${absoluteMintes || 1} 
        ${minutesDifference <= 2
          ? currentLang == "ar"
            ? "دقيقة"
            : "Minute"
          : currentLang == "ar"
            ? "دقائق"
            : "Minutes"
        }`;
    } else if (minutesDifference <= 0) {
      return `${currentLang == "ar" ? "الجلسة " : "Your Session "
        } ${firstName || ""} ${lastName || ""} ${currentLang == "ar" ? "بدأت" : "Started within"
        } ${absoluteMintes || 1} ${minutesDifference <= 2
          ? currentLang == "ar"
            ? "دقيقة"
            : "Minute"
          : currentLang == "ar"
            ? "Minutes"
            : "دقائق"
        } ${currentLang == "ar" ? "قبل" : " Ago"}`;
    }
  }

  updateSlots() {
    this._sessionChecker.OnSessionStarted.subscribe(booking => {
      this.Slot = booking;
    });
  }



  markSlotCompleted() {
    this.calendarService.MarkBookingCompleted(this.Slot[0].id).subscribe(res => {
      this.IsSessionInTime = false;
      this.Slot.status = { ...this.Slot, status: 5 };
      this.toast.info("Rated Successfully");
    });
  }

  checkStatus() {
    if (this.Slot && this.Slot.length && this.Slot[0]['status'] == 4) {
      this.http.post<any>(environment.baseUrl + "Coach/Appointment/startSession/" + this.Slot.id, null).subscribe(res => { })
    }
  }

  startSessionById(visit) {
    let data;

    if (visit.appointmentType == 1) {
      data = {
        'user_id': visit.coachee.id,
        'coach_id': visit.coach.id,
        'session_id': visit.id
      };
    } else if (visit.appointmentType == 2) {
      data = {
        'coach_id': visit.coach.id,
        'session_id': visit.id
      };
    }

    if (this.appCom.isUser.value) {
      this.router.navigate(["communicate"], { queryParams: data });
    } else if (this.appCom.isCoach.value && visit.status == this.coachBookingStatus.Confirmed) {
      this.http.post<any>(environment.baseUrl + "Coach/Appointment/startSession/" + visit.id, null).subscribe(res => {
        if (res.errorCode == 0) {
          this.router.navigate(["communicate"], { queryParams: data });
        }
      });
    } else if (this.appCom.isCoach.value && visit.status == this.coachBookingStatus.SessionStarted) {
      this.router.navigate(["communicate"], { queryParams: data });
    }

  }


  showRateDialog = () => {
    this.router.navigate(['/communicate/take-out'], {
      queryParams: {
        session_id: this.Slot[0].id,
        user_id: this.Slot[0].coachee ? this.Slot[0].coachee.id : null,
        coach_id: this.Slot[0].coach.id
      }
    })
  }

  skip = () => {
    this.isSkipRateModal = true;
    this.modalRef.hide();
  }

  rate = () => {
    this.isSkipRateModal = true;
    this.modalRef.hide();
  }


  openRateModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
    });
    this.modalService.onHide.subscribe((res) => {
      if (this.isSkipRateModal) {
        if (this.isCoach()) {

          let req = new FormData();

          req.append("AppointmentId", this.Slot[0].id);
          req.append("Rate", this.rating.value);
          req.append("Comment", this.comment.value);

          this.calendarService.coachRateCoachee(req).subscribe(res => {
            this.markSlotCompleted();
            this.rating.reset();
            this.comment.reset();
          })
        }
        else if (!this.isCoach()) {

          let req: any = {
            appointmentId: this.Slot[0].id,
            rate: this.rating,
            comment: this.comment
          }

          this.calendarService.coachRateCoachee(req).subscribe(res => {
            this.markSlotCompleted();
            this.rating.reset();
            this.comment.reset();
          })
        }

      } else {
        this.markSlotCompleted()
      }

    });
  }

  goToFeedBackPage = () => {
    if (this.Slot[0].appointmentType == 1) {
      this.router.navigate(['/communicate/take-out'], {
        queryParams: {
          session_id: this.Slot[0].id,
          user_id: this.Slot[0].coachee.id,
          coach_id: this.Slot[0].coach.id
        }
      }).then(() => {
        location.reload()
      })
    } else if (this.Slot[0].appointmentType == 2) {
      this.router.navigate(['/communicate/take-out'], {
        queryParams: {
          session_id: this.Slot[0].id,
          coach_id: this.Slot[0].coach.id
        }
      }).then(() => {
        location.reload()
      })
    }

  }


}
