import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
import { SessionService } from "../global_services/session.service";
import { BehaviorSubject, Observable } from "rxjs";
import { ClaimRoles } from "src/app/common/enums";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class NotificationsService {

  public Notification$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  
  
  private socket;
  public static LoggedUserDataProvided = false;

  constructor(private _session: SessionService, private http: HttpClient) {
    return
    this.socket = io(environment.application_base);
    this.InitializeLoggedUserDataProvider();
  }

  public InitializeLoggedUserDataProvider() { 
    return
    if (!this.socket.connected) {
      this.socket = io.connect(environment.application_base, {
        forceNew: true
      });
    }

    this.socket.on("ProvideLoggedUser", () => {
      this.ProvideLoggedUserData();
    });
  }

  public ProvideLoggedUserData() {
    return
    if (!NotificationsService.LoggedUserDataProvided) {
      let userData = this._session.GetUserData();
      if (userData) {
        userData.profile.role = userData.roles[0].id;
        this.socket.emit("UserDataProvided", {
          isAuthenticated: true,
          profile: userData.profile
        });
        NotificationsService.LoggedUserDataProvided = true;
      }
    }
  }

  public OnNotificationsArrived(): Observable<any> {
    return
    let userData = this._session.GetUserData();
    // except CMS admins from recieving notifications
    if (
      userData === null ||
      userData.roles[0].id === ClaimRoles.SystemAdmin 
    ) {
      return new Observable<any>(observer => {
        observer.next(null);
      });
    }
    return new Observable<any>(observer => {
      this.socket.on("ReadNotification", (data: any) => observer.next(data));
    });
  }

  public OnOtherClaimJoined(): Observable<any> {
    return
    return new Observable<any>(observer => {
      this.socket.on("OtherClaimJoinedSession", (data: any) =>
        observer.next(data)
      );
    });
  }

  public EmitJoinSession(sessionData: any) {
    return
    if (this.socket) this.socket.emit("ClaimEnteredSession", sessionData);
  }

  public Disconnect() {
    return
    if (this.socket) {
      let userData = this._session.GetUserData();
      NotificationsService.LoggedUserDataProvided = false;
      this.socket.emit("userDisconnect", userData);
      this.socket.disconnect();
    }
  }


  getNotification = (pageSize, pageNumber) => { 
    return this.http.get<any>(environment.baseUrl + `Notification?&PageSize=${pageSize}&PageNumber=${pageNumber}`) 
  } 

 
  
}
