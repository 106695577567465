import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class SessionService {
  private readonly UserFieldName = "UserData";

  constructor(private http: HttpClient) { }

  public SaveUserData(userData: any) {
    localStorage.setItem(this.UserFieldName, JSON.stringify(userData));
  }

  public GetUserData() {
    var userString = localStorage.getItem(this.UserFieldName);
    return userString !== null ? JSON.parse(userString) : null;
  }

  public ResetUserData() {
    localStorage.removeItem(this.UserFieldName);
    sessionStorage.clear();
  }

  public ClearSession() {
    localStorage.clear();
  }

  AttendAppointment = (appointmentId) =>
    this.http.patch<any>(environment.api_base + "Shared/AttendAppointment?appointmentId=" + appointmentId, null);



  GetAuthCode = (appointmentId) => this.http.get<any>(environment.baseUrl + "Appointment/GetAuthCode?AppointmentId=" + appointmentId);

  callBack = (req) => this.http.post<any>(environment.baseUrl + "auth/callback", req); 


}
