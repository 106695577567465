import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-multiple-input-field",
  templateUrl: "./multiple-input-field.component.html",
  styleUrls: ["./multiple-input-field.component.css"]
})
export class MultipleInputFieldComponent implements OnInit {
  @Input() NoItemsYet: string;
  @Input() Items: any[] = new Array<any>();
  itemToAddValue: string;

  constructor() {}

  ngOnInit() {}

  public AddItem(event) {
    if (this.itemToAddValue && this.itemToAddValue.length > 0) {
      this.Items.push(this.itemToAddValue);
      this.itemToAddValue = "";
    }
  }

  public removeItem(event, i) {
    this.Items.splice(i, 1);
  }
}
