import { Component, OnInit, Input } from "@angular/core";
import { DateHelpers } from "src/app/common/helpers";
import { CourseLevel } from "src/app/common/enums";

@Component({
  selector: "app-course-card",
  templateUrl: "./course-card.component.html",
  styleUrls: ["./course-card.component.css"]
})
export class CourseCardComponent implements OnInit {
  @Input("course") course: any;

  constructor() {}

  ngOnInit() {}

  GetCourseLevelText(courseLevel) {
    let level = CourseLevel[courseLevel];
    return level.toString();
  }

  public TimeOnly(date) {
    let newDate;
    if (typeof date === "string") {
      newDate = date
        .replace(/-/g, "/")
        .replace(/T/g, " ")
        .substring(0, date.length - 4);
    }
    return DateHelpers.TimeFormatted(newDate ? new Date(newDate) : date);
  }

  public DateOnly(date) {
    let newDate;
    if (typeof date === "string") {
      newDate = date
        .replace(/-/g, "/")
        .replace(/T/g, " ")
        .substring(0, date.length - 4);
    }
    return DateHelpers.DateFormatted(newDate ? new Date(newDate) : date);
  }
}
