import { Injectable , EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsService {

  public static UserLoggedIn : EventEmitter<any>  = new EventEmitter<any>();
  public static UserLoggedOut : EventEmitter<any>  = new EventEmitter<any>();


  public static IncommingSession : EventEmitter<any>  = new EventEmitter<any>();


  constructor() { }
}
