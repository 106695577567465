import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pagenation } from 'src/app/common/pagenation';

@Component({
  selector: 'app-paging-control',
  templateUrl: './paging-control.component.html',
  styleUrls: ['./paging-control.component.css']
})
export class PagingControlComponent implements OnInit {

  @Input("Paging") Paging: Pagenation = null;
  @Output("NextClicked") NextClicked = new EventEmitter();
  @Output("PreviousClicked") PreviousClicked = new EventEmitter();
  @Output("PagingChanged") PagingChanged = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }


  __nextClicked() {
    this.Paging.page++;
    this.NextClicked.emit(this.Paging);
    this.PagingChanged.emit(this.Paging);
  }


  __previousClicked() {
    if (this.Paging.page == 0) return;
    this.Paging.page--;
    
    this.PreviousClicked.emit(this.Paging);
    this.PagingChanged.emit(this.Paging);
  }

}
