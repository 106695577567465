import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core"; 
import { environment } from "src/environments/environment";
  

@Injectable({
    providedIn: "root"
})
export class AssessmentService {
 
    imageFile: File = null;
    imageUrl: string = ""; 

    constructor(private http: HttpClient ) { } 

    assessmentSearch = () => this.http.post<any>(environment.baseUrl + "Assessment/Search", null)
    
    assessmentCreate = (req) => this.http.post<any>(environment.baseUrl + "Assessment/Create", req);

    assessmentUpdate = (req) => this.http.post<any>(environment.baseUrl + "Assessment/Update", req);  

}








