import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-corporates',
  templateUrl: './corporates.component.html',
  styleUrls: ['./corporates.component.css'] 
})
export class CorporatesComponent implements OnInit {
  _activeIndex: number = 1; 
  _lang: string;

  constructor(
    public translate: TranslateService,
    private elem: ElementRef,
    private _translationService: TranslationService,
    ) {
    //Translation Service
    let lang = localStorage.getItem("lang");
    if(lang){ this._lang = lang; }
    else{ this._lang = 'ar'; }
    translate.use(this._lang);
  }

  ngOnInit() { 
  }

  //#region Methods
  isArabic(): boolean { return this._lang == 'ar'; }

  getCarouselIndex(carousel: any): number {
    let index: number = 1;
    let activeItem = this.elem.nativeElement.querySelectorAll('.carousel-item.active')[0];

    index = Array.from(carousel.children[0].children).indexOf(activeItem);
    return index;
  }
  //#endregion

  //#region Events
  carouselChanged(carousel: any) {
    setTimeout(() => {
      this._activeIndex = this.getCarouselIndex(carousel) + 1;
    }, 1000);
  }

  requestDemo(){
  }
  //#endregion
}
