export enum CourseSortByMethod {
  Price = 1,
  Date = 2
}
export enum CourseSortByMethodArabic {
  السعر = 1,
  التاريخ = 2
}

export enum CoachSort {
  Price = 1,
  Date = 2
}

export enum CoachPrice {
  "Highest - Lowest" = 2,
  "Lowest - Highest" = 1,
  // "$$$" = 3
}
export enum ArabicCoachPrice {
  "Highest To Lowest" = 2,
  "Lowest - Highest" = 1
  // "$$$" = 3
}

export enum CoachBookingStatus {  
  New = 1, 
  Pending = 2,
  Rejected = 3, 
  Confirmed = 4,
  SessionStarted = 5,
  WaitingforPayment = 12,  
  CanceledByUser = 6,
  CanceledByCoach = 7,
  Completed = 8,
  Expired = 9, 
  DidNotShow = 11
}

export enum CoachCalendarSlotType {
  Available = 0,
  Reserved = 1,
  PartiallyReserved = 3
}

export enum CourseLevel {
  Beginner = 0,
  Intermediate = 1,
  Advanced = 2
}

export enum ClaimRoles {
  Coach = 8,
  User = 7,
  SystemAdmin = 2
  // Coach = 0,
  // User = 1,
  // SystemAdmin = 2
}

export enum NotificationTypes {
  IncomingSession = 0,
  SessionStatusChanged = 1,
  System_Notification_To_All = 2,
  System_Notification_To_Coaches = 3,
  System_Notification_To_Users = 4,
  System_Notification_To_Coach = 5,
  System_Notification_To_User = 6
}

export enum TransactionsTypes {
  CoachTransaction = 0,
  CourseTransaction = 1
}

export let EnumHelpers = {
  GetNames: function (_enum: any) {
    let result = [];
    for (var enumMember in _enum) {
      var isValueProperty = parseInt(enumMember, 10) >= 0;
      if (isValueProperty) {
        result.push(_enum[enumMember]);
      }
    }
    return result;
  },
  GetNumericValues(_enum: any) {
    let result = [];
    for (var enumMember in _enum) {
      var isValueProperty = parseInt(enumMember, 10) >= 0;
      if (isValueProperty) {
        result.push(parseInt(enumMember, 10));
      }
    }
    return result;
  },

  GetName: function (_enum: any, itemId) {
    return _enum[itemId];
  },

  ToIdNameDictionary(_enum: any) {
    let result = [];
    let numericValues = this.GetNumericValues(_enum);
    for (let i = 0; i < numericValues.length; ++i) {
      let name = _enum[numericValues[i]];
      result.push({
        id: numericValues[i],
        name: name,
        display: name
      });
    }

    return result;
  },

  GetEnumIdByName(_enum: any, searchFor: string) {
    return _enum[searchFor];
  }
};

export enum AppointmentStatus {
        New = 1,
        Pending = 2,
        Rejected = 3,
        Accepted = 4,
        OnTheWay = 5,
        Arrived = 6,
        SessionStarted = 7,
        CancelledByUser = 8,
        CancelledByProvider = 9,
        Completed = 10,
        Unconfirmed = 11,
        PaymentFailed = 12,
        DidNotShow = 13,
    }