import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "src/app/services/global_services/authentication.service";
import { ClaimRoles } from "src/app/common/enums";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";
import { appService } from "src/app/app.service";
import { SessionService } from "src/app/services/global_services/session.service";
import { GlobalEventsService } from "src/app/services/global_services/global-events.service";
import { BehaviorSubject } from "rxjs";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [AuthenticationService]
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public message: string = "";
  public loginType: string = 'coache';
  private currentUserSubject: BehaviorSubject<any>;
  public roles: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private toastService: ToastrService,
    private sessionService: SessionService,
    private titleService: Title,
    private appService: appService,
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
  }

  ngOnInit() {
    this.titleService.setTitle("Login | Coach you")

    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";

    this.loginForm.valueChanges.subscribe(() => {
      this.message = "";
    });
  }

  get f() {
    return this.loginForm.controls;
  }
 

  onLogin() {
    this.submitted = true; 

    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;

    let payload = new FormData;

    payload.append("Username", this.f.email.value);
    payload.append("Password", this.f.password.value);

    this.authenticationService.authenticate(payload).subscribe(res => { 
      if (res.errorCode == 0) {
        this.loading = false;
 

        if (this.loginType == 'coach') { 
          if (res.data.roles.some(s => s.name == "Coach")) { 
            let index = res.data.roles.findIndex(i => i.name == "User"); 
            if (index > -1)  {
              res.data.roles.splice(index, 1); 
            }
          } else {
            this.toastService.error("This Account is not a Caoch")
            return
          }
        } 
        else if (this.loginType == 'coache'){
          if (res.data.roles.some(s => s.name == "User")) { 
            let index = res.data.roles.findIndex(i => i.name == "Coach"); 
            if (index > -1)  {
              res.data.roles.splice(index, 1); 
            }
          }  else {
            this.toastService.error("You have not an coachee account ")
            return
          }
        }
 
 
        
        this.sessionService.SaveUserData(res.data); 
        GlobalEventsService.UserLoggedIn.emit(res.data);
        this.currentUserSubject.next(res.data);

        if (res.data.roles && res.data.roles.length)
          for (let index in res.data.roles)
            this.roles.push(res.data.roles[index].name);
 
        if (this.loginType == 'coach' && !this.roles.includes("Coach")) {
          this.toastService.error("Your account is not approved yet");
          return;
        }

        this.toastService.success("Successfully Login")
        this.router.navigate(['/']);

        setTimeout(res => {
          location.reload();
        }, 500)

      } else if (res.errorCode == 3) {
        this.loading = false;
        this.toastService.error("Username Or Password is incorrect");
      } 
      else if (res.errorCode == 2) {
        this.loading = false;
        this.toastService.error("User Not Found");
      } else if (res.errorCode == 3) {
        this.loading = false;
        this.toastService.error("User Not Found");
      } else if (res.errorCode == 9) {
        this.loading = false;
        this.toastService.error("Phone Number is inactive");
      } else if (res.errorCode == 25) {
        this.loading = false;
        this.toastService.error("This Account is deactivate"); 
      } 
      else {
        this.loading = false;
        this.toastService.error("Error");
      }
    }, error => {
      this.loading = false;
      this.toastService.error(error);
    })

    return

    if (this.loginType == 'coach') {
      this.authenticationService.coachLogin(this.f.email.value, this.f.password.value).subscribe(data => {
        if (data.success) {
          this.router.navigate(["coaches", "dashboard", "requests"]);
        } else {
          this.message = data.message;
        }
      }, error => {
        this.loading = false;
        this.toastService.error(error.error.error);
      }
      );
    }
    else if (this.loginType == 'coache') {
      this.authenticationService
        .userLogin(this.f.email.value, this.f.password.value)
        .subscribe(
          data => {
            if (data.success) {
              this.router.navigate(["user", "dashboard"]);
            } else {
              this.message = data.message;
            }
          },
          error => {
            console.log('error', error);
            // this.alertService.error(error);
            this.loading = false;
            this.toastService.error(error.error.error);
            console.log(error.error.error);
          }
        );
    }
  }

  changeType(type) {
    this.loginType = type; 
    if (type == "corporate") { 
      setTimeout(() => { 
        window.open("https://www.coachyou.io/Corporate/#/", '_blank');
        this.loginType = "coache"
      }, 1000);
    } 
  }
}
