import { Component, OnInit } from "@angular/core";
import { Router, ActivationEnd } from '@angular/router';

@Component({
  selector: "app-confirmation-error",
  templateUrl: "./confirmation-error.component.html",
  styleUrls: ["./confirmation-error.component.css"],
})
export class ConfirmationErrorComponent implements OnInit {
  error: any;

  constructor(private router: Router) {
    router.events.subscribe(val => {
      if (val instanceof ActivationEnd) {
        this.error = val.snapshot.params.er
      }
  });
}

  ngOnInit() {}
}
