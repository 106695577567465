import { appService } from 'src/app/app.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ChangePasswordService } from './change-password.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {


  form: FormGroup
  
  constructor(private toast: ToastrService, private service: ChangePasswordService, private route: Router) { }

  ngOnInit() {
    this.form = new FormGroup({
      oldPassword: new FormControl("", Validators.required),
      newPassword: new FormControl("", Validators.required),
      confirmPassword: new FormControl("", Validators.required),
    })
  }

  changePassword = () => {
    if (this.form.invalid) {
      this.toast.error("Please, fill all inputs ");
      return;
    }

    let newPassword: string = this.form.get("newPassword").value;
    let confirmPassword: string = this.form.get("confirmPassword").value;

    if (newPassword !== confirmPassword) {
      this.toast.error("Passwords is not matched");
      return;
    }

    let req: FormData = new FormData();

    req.append("OldPassword", this.form.get("oldPassword").value);
    req.append("NewPassword", this.form.get("newPassword").value); 

    this.service.changePassword(req).subscribe(res => {
      if (res.errorCode == 0) {
        this.toast.success("Password has been changed successfully");
        this.route.navigate(['/'])
      } else if (res.errorCode == 3){
        this.toast.error("Old password is incorrect");
        return;
      } else {
        this.toast.error("sometiong has went error, please try again another time");
      }
    })
  }
  
}

