import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DateHelpers } from "src/app/common/helpers";
import { ClaimRoles } from "src/app/common/enums";
import { SessionService } from "src/app/services/global_services/session.service";
import { RequestsService } from "src/app/services/requests/requests.service";
import { Router } from "@angular/router";
import { _12HoursTo24BasedMapping } from "src/app/common/12HoursTo24Mappings";

@Component({
  selector: "app-session-call-box",
  templateUrl: "./session-call-box.component.html",
  styleUrls: ["./session-call-box.component.css"]
})
export class SessionCallBoxComponent implements OnInit {
  @ViewChild("audioElement", { static: false }) audioElement: ElementRef;

  public IsClosed: boolean = true;
  public SessionData;
  public static OnlyInstanceAccessor: SessionCallBoxComponent;
  public OtherMember;

  constructor(
    private session: SessionService,
    private _requestsService: RequestsService,
    private _router: Router
  ) {
    SessionCallBoxComponent.OnlyInstanceAccessor = this;
  }

  ngOnInit() {}

  public Show(sessionId) {
    if (this._router.url.indexOf("/communicate") > -1) return;

    this._requestsService.GetBookingById(sessionId).subscribe(response => {
      this.SessionData = response.result;
      let loggedRole = this.session.GetUserData().authentication.role;
      this.OtherMember =
        loggedRole === ClaimRoles.Coach
          ? this.SessionData.user
          : this.SessionData.calendar_slot.coach;
      this.IsClosed = false;
      this.audioElement.nativeElement.play();
    });
  }

  public Hide() {
    this.IsClosed = true;
    this.StopAudio();
  }

  // to format a date
  public dateFormatted(date) {
    return new Date(date);
  }

  // format time
  public timeFormatted(date) {
    let newDate;
    if (typeof date === "string") {
      newDate = date
        .replace(/-/g, "/")
        .replace(/T/g, " ")
        .substring(0, date.length - 4);
    }
    return DateHelpers.TimeFormatted(newDate ? new Date(newDate) : date);
  }

  public GoAlive(userId, coachId, sessionId, slot_id) {
    let data = {
      user_id: userId,
      coach_id: coachId,
      session_id: sessionId,
      slot_id
    };
    this._router.navigate(["communicate"], { queryParams: data });
    this.StopAudio();
  }

  public StopAudio() {
    this.audioElement.nativeElement.pause();
    this.audioElement.nativeElement.currentTime = 0;
    // this.audioElement.nativeElement.stop();
  }
}
