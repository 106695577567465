import { Injectable } from "@angular/core";
import { EventEmitter } from "@angular/core"; 

@Injectable({
  providedIn: "root",
})
export class SessionCheckerService {
  public OnSessionStarted: EventEmitter<any> = new EventEmitter();
  public IntervalTicks = 1000 * 20; // every 1 minute 
 

  private stdTimezoneOffset = function (date) {
    var jan = new Date(date.getFullYear(), 0, 1);
    var jul = new Date(date.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  };

  private isDstObserved = function () {
    var today = new Date();
    return today.getTimezoneOffset() < this.stdTimezoneOffset(today);
  };
}
