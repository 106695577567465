import { Component, OnInit, Input, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upper-border',
  templateUrl: './upper-border.component.html',
  styleUrls: ['./upper-border.component.css']
})
export class UpperBorderComponent implements OnInit {

  @Input("Title") Title: string;
  @Input("SubTitle") SubTitle: string;
  @Input("Bread") Bread: string;
  @Input("Image") Image: string;
   
  constructor() { }

  IsMobile: boolean=false;
  ngOnInit() {
    if (window.innerWidth < 995) {
      this.IsMobile = true
    }
    if (window.innerWidth >= 995) {
      this.IsMobile = false
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 995) {
      this.IsMobile = true
    }
    if (window.innerWidth >= 995) {
      this.IsMobile = false
    }
  }

  baseUrl = environment.baseUrl + "File/get/";
  
  getUserData = (): any => JSON.parse(localStorage.getItem("UserData"));

}
