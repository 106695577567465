import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Pagenation } from 'src/app/common/pagenation';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InvitationsService {
    constructor(private http: HttpClient) { }

    public CheckAvailableInvitation(code) {
        console.log('code in api', code);
        console.log();
        let targetUrl = `${environment.api_base}/public/invitation/available_invitation`;
        console.log('targetUrl', targetUrl);
        return this.http.post<any>(targetUrl, { code });
    }

}
