import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private http: HttpClient) { }

  public async RegisterUser(UserData : FormData){
    return await this.http.post<any>(`${environment.api_base}/public/accounts/register_user`, UserData).toPromise();
  }

  public async RegisterCoach(CoachData : FormData){
    return await this.http.post<any>(`${environment.api_base}/public/accounts/register_coach`, CoachData).toPromise();
  }

  signUp = (payload: FormData) => this.http.post<any>(`${environment.baseUrl}Account/register`, payload);





}
