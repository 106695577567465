import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-expandable-panel',
  templateUrl: './expandable-panel.component.html',
  styleUrls: ['./expandable-panel.component.css']
})
export class ExpandablePanelComponent implements OnInit {



  @Input() Image: string;
  @Input() FirstLine: string;
  @Input() SecondLine: string;
  public isCollapsed = true;

  constructor() { }

  ngOnInit() {

  }

}
