import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css']
})
export class DateRangeComponent implements OnInit {

  DateRange = null;
  @Output() DateChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() DateCleared: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }


  DateFilterChange(startEndArray) {
    this.DateRange = startEndArray;
    this.DateChanged.emit(startEndArray);
  }


  ClearDateRange() {
    this.DateRange = null;
    this.DateCleared.emit();
  }


}
