import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-user-confirmation-done",
  templateUrl: "./user-confirmation-done.component.html",
  styleUrls: ["./user-confirmation-done.component.css"],
})
export class UserConfirmationDoneComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
