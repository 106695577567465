import { countries } from './../../data/countries';

import { environment } from './../../../environments/environment'; 
import { Component, OnInit, ViewChild, ElementRef, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AccountsService } from "src/app/services/global_services/accounts.service";
import { ToastrService } from "ngx-toastr";
import { MiscService } from "src/app/services/misc.service";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { TranslationService } from '../../services/translation.service';
import { ar_genders, en_genders } from '../../data/lockup';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: "app-register-coach",
  templateUrl: "./register-coach.component.html",
  styleUrls: ["./register-coach.component.css"],
  providers: [DatePipe]
})
export class RegisterCoachComponent implements OnInit {
  @ViewChild("imageInput", { static: true }) ProfileImage: ElementRef;


  allowedCountries: string[] = [];
  genders;

  file: File = null;

  specialities: any
  phoneNumber
  userId
  allCountries = countries
  services
  nextSubmitButtonLabel = 'Next';
  buttonLabel2 = 'Finish';
  buttonLabel1 = 'Next';
  registerForm: FormGroup;
  submitted = false;
  returnUrl: string;
  message: string = "";
  countries: any[]
  languages: any[] = [];
  imagePreviewSrc;
  boundCertifications = Array<HTMLInputElement>();
  boundLanguages = Array<HTMLInputElement>();
  specialitiesOptions = [];
  isArabic: boolean;

  selectedCountry = [];

  @ViewChild("AreYouWillingToJoinCoachYouCorporateYes", { static: false }) AreYouWillingToJoinCoachYouCorporateYes: any
  @ViewChild("AreYouWillingToJoinCoachYouCorporateNo", { static: false }) AreYouWillingToJoinCoachYouCorporateNo: any

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountsService: AccountsService,
    private ToastrService: ToastrService,
    private miscService: MiscService,
    private translate: TranslateService,
    private titleService: Title,
    private _tranalationService: TranslationService,
    private http: HttpClient,
    private toastrService: ToastrService,
    private datePipe : DatePipe,
  ) { }



  @HostListener("window:scroll", [])
  onScroll(btn) {
    $(".search-container label").css("top", "4px");
    if (this.translate.currentLang == "ar") {
      this.isArabic = true;
      $("span").css("float", "right");
      $(".search-container label").css("right", "5px");
    } else {
      this.isArabic = false;
      $("span").css("float", "left");
      $(".search-container label").css("left", "5px");
    }
  }
  ngOnInit() {
    this.genders = this.translate.currentLang == "ar" ? this.genders = ar_genders : this.genders = en_genders;
    console.log(this.translate.currentLang);
    this.allCountries.map(item => {
      item.name = item.name + " " + item.code
    });
    this.titleService.setTitle("Sign up to start your own coaching business ")
    this.registerForm = this.formBuilder.group({
      email: ["", [Validators.email, Validators.required]],
      password: ["", Validators.required],
      confirm_password: ["", Validators.required],
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      birth_of_date: ["", Validators.required],
      mobile_number: ["", Validators.required],
      linkedin_link: [""],
      years_of_experience: ["", Validators.required],
      position: ["", Validators.required],
      country: [null, Validators.required],
      price: ["", Validators.required],
      languages: ["", Validators.required],
      gender: [, Validators.required],
      bio: ["", Validators.required],
      specialities: [""],
      image: ["", Validators.required],
      service: [""],
      WhyToHaveACoachingSessionWithMe: [''],
      AreYouWillingToJoinCoachYouCorporate: [''],

    });

    this.http.get<any>(environment.baseUrl + "").subscribe((response) => {


      this.specialitiesOptions = response.result;
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";

    // detect if a field changed and hide validation message
    this.registerForm.valueChanges.subscribe(() => {
      this.message = "";
    });



    this.getCountries();
    this.getLanguage();
    this.getServices();
    this.getSpecialities();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  getLanguage() {
    this.http.post<any>(environment.baseUrl + "Languages/Search", { pageSize: 999, pageNumber: 1 }).toPromise().then(res => {
      this.languages = res.data.data;

    })
  }


  async onSubmit() {

    let b = this.registerForm.get("birth_of_date").value
    let dateofbirth = this.datePipe.transform(b, 'yyyy-MM-ddTHH:mm:ss');
    
    this.submitted = true;
    if (this.registerForm.controls.mobile_number.status == "INVALID") {
      this.ToastrService.error("Error", this._tranalationService.getTranslationValue('MobileNumberInvalid'));
      return;
    }
    //console.log(this.registerForm.value);

    if (this.registerForm.invalid) {
      this.ToastrService.error("Error", "Please fill out requiered fields");
      return;
    }

    // check password confirm
    if (this.registerForm.controls.password.value != this.registerForm.controls.confirm_password.value) {
      alert("confirm password first");
      return;
    }


    let payload = new FormData();

    payload.append("firstName", this.registerForm.get("first_name").value);
    payload.append("lastName", this.registerForm.get("last_name").value);
    payload.append("Gender", this.registerForm.get("gender").value);
    payload.append("Speciality", "null");
    payload.append("DateOfBirth", new Date(this.registerForm.get("birth_of_date").value).toISOString()); 
    
    payload.append("Email", this.registerForm.get("email").value);
    payload.append("Postion", this.registerForm.get("position").value);
    payload.append("YearsOfExperience", this.registerForm.get("years_of_experience").value);
    payload.append("About", this.registerForm.get("bio").value);
    payload.append("IsCoachyouTeam", "false");
    payload.append("countryId", this.registerForm.get("country").value);
    payload.append("price", this.registerForm.get("price").value);
    payload.append("linkedInLink", this.registerForm.get("linkedin_link").value);
    payload.append("phoneNumber", this.registerForm.get("mobile_number").value);
    payload.append("password", this.registerForm.get("password").value);
    payload.append("WhyCoachingSession", this.registerForm.get("WhyToHaveACoachingSessionWithMe").value);
    payload.append("Picture", this.registerForm.get("image").value);
    
    // payload.append("Service", "1");

    this.AreYouWillingToJoinCoachYouCorporateYes.nativeElement.checked && payload.append("WillingToJoinCorporate", "true");
    this.AreYouWillingToJoinCoachYouCorporateNo.nativeElement.checked && payload.append("WillingToJoinCorporate", "false");

    
    if (this.registerForm.get("specialities").value) {
      this.registerForm.value.specialities.forEach(element => {
        payload.append("Services", element);
      });
    }

    if (this.registerForm.get("languages").value) {
      this.registerForm.value.languages.forEach(element => {
        payload.append("Languages", element);
      });
    }

    if(this.file){
      payload.append("Certifications", this.file); 
      payload.append("CertificateName", this.file.name); 
      // let x = []
      // x.push(this.file)
      // payload.append("Certifications", x.toString()); 
      // this.file.map(item => payload.append("Certifications", item))
    }else {
      payload.append("Certifications", null); 
      payload.append("CertificateName", null); 
    }
    console.warn(this.file)

    this.http.post<any>(environment.baseUrl + "CoachRequest", payload).subscribe(res => {
      if (res.errorCode == 0) {
        this.router.navigate(["confirm_registration"], {
          queryParams: {
            email: this.registerForm.get("email").value,
            userId: res.data,
            registerType: "Coach"
          }
        });
      } else if (res.errorCode == 28) {
        this.ToastrService.error(this.translate.currentLang == "ar" ? "لديك طلب مسبق" : " you have previously requested");
      } else if(res.errorCode != 0){
        this.ToastrService.error(res.errorMessage);
      }
    })

  }


  getCountries() {
    this.http.get<any>(environment.baseUrl + "Country").subscribe(res => {
      this.countries = res.data;
    })
  }

  public openFileChooser(event) {
    this.ProfileImage.nativeElement.click();
  }

  imageChanged(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onload = (e) => {
        this.imagePreviewSrc = reader.result;
      };

      reader.readAsDataURL(file);
    }
    this.registerForm.controls.image.setValue(
      this.ProfileImage.nativeElement.files[0]
    );
  }

  onCertificateSizeError(size) {
    this.ToastrService.error(
      this.translate.currentLang == "ar"
        ? "حجم ملف الشهادة يجب ان  لا يتجاوز 10 ميغا بايت"
        : "Certificate Size Should Not Exceed 10 mega bytes"
    );
  }


  onCodeCompleted(code: string) {

    let reqPayload = new FormData();
    reqPayload.append("UserId", this.userId);
    reqPayload.append("VerificationCode", code);

    this.http.post<any>(environment.baseUrl + "Account/verify", reqPayload).subscribe(res => { 
      if (res.errorCode == 0) {
        this.toastrService.success(this.translate.currentLang == "ar" ? "تم التحقق بنجاح" : "Verified Successfully"); 
        this.router.navigate(['/login'])
      } else if (res.errorCode == 7) {
        this.toastrService.error(this.translate.currentLang == "ar"
          ? "رمز التحقق غير صحيح"
          : "Verification code is incorrect"
        );
      }
      else if (res.errorCode === 0) {
        this.toastrService.error(this.translate.currentLang == "ar" ? "لديك طلب مسبق" : "you have previously requested"); 
      }
    }, error => {
      this.toastrService.error("Something has went wrong.")
    })

  }
  resendTheCode() {
    this.http.post<any>(environment.baseUrl + "Account/resendVerificationCode/" + this.phoneNumber, null).subscribe(res => {
      if (res.errorCode == 0) {
        this.toastrService.success(this.translate.currentLang == "ar" ? "تاكد من هاتفك الان" : " Check your Email now");
      } else if (res.errorCode == 7) {
        this.toastrService.error(this.translate.currentLang == "ar" ? "حدث خطا ما " : "Error");
      }
    }, error => {
      this.toastrService.error("Something has went wrong.");
    })
  }


  getServices() {
    this.http.get<any>(environment.baseUrl + "CoachingService").subscribe(res => {
      if (res.errorCode === 0) {
        this.specialities = res.data;
      }
    })
  }
  
  getSpecialities() {
    let id = this.registerForm.get("service").value
    this.http.get<any>(environment.baseUrl + "Speciality?CoachingServiceId=" + id).subscribe(res => {
      if (res.errorCode == 0) {
        this.services = res.data;
      }
    })
  }

  
getFileUploader(e) {
    if (e.target.files) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        this.file = e.target.files[0]
        // this.file.push(e.target.files[0]);
        // reader.onload = (event: any) => {
        //     this.file = event.target.result
        // }
    }
  } 


}
