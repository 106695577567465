import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser"; 
import { TranslationService } from "src/app/services/translation.service";

@Component({
  selector: "app-faqmain-page",
  templateUrl: "./faqmain-page.component.html",
  styleUrls: ["./faqmain-page.component.css"]
})
export class FAQMainPageComponent implements OnInit {
  public Faqs = [];
  pressedId: number = null;
  constructor( 
    private _tranalationService: TranslationService,
    private titleService: Title,
    private http: HttpClient
  ) { 
  }

  ngOnInit() {
    this.titleService.setTitle("FAQS | Coach you")
    const items = document.querySelectorAll(".accordion a");

    function toggleAccordion() {
      this.classList.toggle("active");
      this.nextElementSibling.classList.toggle("active");
    }

    items.forEach(item => item.addEventListener("click", toggleAccordion));
    // this.faqService
    //   .GetAllSectionsGrouped()
    //   .subscribe(response => (this.Faqs = response.result));

    this.http.get<any>(environment.baseUrl + "Faq?PageSize=999&PageNumber=1", { headers: new HttpHeaders({
      "Accept-language": localStorage.getItem("lang")
    }) }).subscribe(res => {
      this.Faqs = res.data.data
    })
    
  }
  setFaqId(faqId) {
    if (this.pressedId == null) {
      this.pressedId = faqId;
    } 
  }
  resetFaqId() {
    this.pressedId = null;
  }
  isArabic() {
    var lang = this._tranalationService.getLanguage();
    if (lang == "ar") return true;

    return false;
  }
}
