import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  constructor(private http: HttpClient) {}

  public GetCalendar() {  
    let client_time = new Date().toISOString();
    let targetUrl = `${environment.api_base}/private/calendar/get_calendar?client_time=${client_time}`;
    return this.http.get<any>(targetUrl);
  }

  public GetCalendarPublic(coachId: number) {  
    // let client_time = new Date().toISOString();
    // let targetUrl = `${environment.baseUrl}CoachSlot?From=11%2F18%2F2020&To=11%2F18%2F2029`;
    // return this.http.get<any>(targetUrl);
    let client_time = new Date().toISOString();
    let targetUrl = `${environment.baseUrl}CoachSlot?From=11%2F18%2F2020&To=11%2F18%2F2029`;
    return this.http.get<any>(targetUrl);
  }

  public SetAvailableDateTime(dateTime: string, endDate: string, days) {
    // let targetUrl = `${environment.api_base}/private/calendar/set_available`;
    // return this.http.post<any>(targetUrl, { date_time: dateTime });
    
    // let index = dateTime.indexOf("T"); 
    // var endDate = Number(dateTime.slice(index +1, index +3)) + 1;
    
    // console.log(dateTime);
    // console.log(endDate);

    let req = new FormData();

    req.append("From", dateTime);
    req.append("To", endDate);
    req.append("RepeatDaily", 'false');
    for (let i in days) {
      req.append("Days", days[i]);
    }
    req.append("Classification", '2'); 
    
    
    
    let targetUrl = `${environment.baseUrl}CoachSlot`;
    return this.http.post<any>(targetUrl, req);
  }

  public deleteTimeSlot(id: number) {
    // /api/CoachSlot/{id}
    // hcpRequest = (req: FormData) => this.http.post<res>(this.api + "HcpRequest", req);
    return this.http.delete<any>(`${environment.baseUrl}CoachSlot/${id}`)
  }

  public SetUnAvailableDateTime(dateTime: string) {
    return
    let targetUrl = `${environment.api_base}/private/calendar/set_unavailable`;
    return this.http.post<any>(targetUrl, { date_time: dateTime });
  }

  public BookSession(calendarSlotId: number, situation: string) { 
    let targetUrl = `${environment.baseUrl}`;
    return this.http.post<any>(targetUrl, {
      calendar_slot_id: calendarSlotId,
      situation: situation,
    });
  }

  public BookCourseSession(
    calendarSlotId: number,
    courseId: number,
    situation: string
  ) {
    return
    let targetUrl = `${environment.api_base}/private/calendar/book_session`;
    return this.http.post<any>(targetUrl, {
      calendar_slot_id: calendarSlotId,
      situation: situation,
      is_course_booking: true,
      course_id: courseId,
    });
  }
  public MarkBookingCompleted(appointmentId) {
    let targetUrl = `${environment.baseUrl}Coach/Appointment/complete/${appointmentId}`;
    return this.http.post<any>(targetUrl, null, { headers: new HttpHeaders({
      
    })});
  }

  public CheckBookingStatus(calendar_slot_id) {
    return
    let targetUrl = `${environment.api_base}/private/calendar/check_booking_status?calendar_slot_id=${calendar_slot_id}`;
    return this.http.get<any>(targetUrl);
  }

  coachRateCoachee = (req: FormData) => this.http.post<any>(environment.baseUrl + "Coach/Appointment/rate", req) 

  coacheeRateCoach = (req: object) =>  this.http.post<any>(environment.baseUrl + "Appointment/rate", req)
  
  
}
