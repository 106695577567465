import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
 
 

  export class res {
      data: any;
      errorCode: number;
      errorMessage: string;
  }

@Injectable({
    providedIn: "root"
})
export class appService {
 
    imageFile: File = null;
    imageUrl: string = ""; 
 
    constructor( 
        private matSnackBar: MatSnackBar, 
        private route: ActivatedRoute
        ) { }


    showErrorMessageStatic = (errorMessage: string) => {
        this.matSnackBar.open("Something Went wrong, Please try again later", 'Got it', { duration: 4000, panelClass: 'error' });
        console.error(errorMessage);
    };

    showErrorMessage = (successMessage: string) => this.matSnackBar.open(successMessage, '', { duration: 4000, panelClass: "error" });
    
    showSuccessMessage = (successMessage: string) => this.matSnackBar.open(successMessage, '', { duration: 4000, panelClass: "success" });

    showInfoMessage = (infoMessage: string) => this.matSnackBar.open(infoMessage, ' ', { duration: 4000, panelClass: 'info' });
    
    showWarningMessage = (warningMessage: string) => this.matSnackBar.open(warningMessage, '', { duration: 4000, panelClass: "warning" });

    async ConfirmDialogMessage(deleteFunction: Function) {
  
    }  
 
    getRouterParams = (getParam, getByIdFunction: Function) => {
        this.route.queryParams.subscribe(param => {
            if (!getParam) return;
            getByIdFunction();
        })
    }

    getImageFile(name: string) {
        return name ? environment.baseUrl + "File/get/" + name : ""
    }

    getFileUploader(e) {
        if (e.target.files) {
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0])
          this.imageFile = e.target.files[0]
          reader.onload = (event: any) => {
            this.imageUrl = event.target.result
          }
        }
      }

      getDateFormate(date) { 
        let indexT = date.indexOf("T");
        let dateAfterCrop = date.slice(0, indexT); 
        let dd = dateAfterCrop.slice(8, dateAfterCrop.length);
        let mm = dateAfterCrop.slice(5, 7);
        let yyyy = dateAfterCrop.slice(0, 4);
        return (`${yyyy}-${mm}-${dd}`); 
      }
    
      getConfiguration = () => { 
        
      }
      
      
}








