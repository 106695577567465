import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';
import { GlobalEventsService } from './global-events.service';
import { ClaimRoles } from 'src/app/common/enums';
import { appService } from 'src/app/app.service';
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;


    constructor(
        private http: HttpClient, 
        private sessionService: SessionService,
        private appService: appService

        ) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.api_base}/public/accounts/login`, { email, password })
            .pipe(map(loginData => {
                // login successful if there's a jwt token in the response
                if (loginData && loginData.success) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.sessionService.SaveUserData(loginData);
                    // fire logging event
                    GlobalEventsService.UserLoggedIn.emit(loginData);
                    //localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(loginData);
                }
                return loginData;
            }));
    }

    logout() { 
        this.sessionService.ResetUserData(); 
        GlobalEventsService.UserLoggedOut.emit();
        this.currentUserSubject.next(null);
    }


    CMSAdminlogout() { 
        this.sessionService.ResetUserData();
    }


    isLoggedUser() {  
        let userData = this.sessionService.GetUserData();
        if (userData === null || !userData.token) return false;

        // if (userData.role === ClaimRoles.SystemAdmin) return false;

        

        if (userData && userData.refreshToken.validUntil) { 
            var expirationDate = userData.refreshToken.validUntil; 
            var now = new Date("YYYY-MM-DDTHH:mm:ss.sssZ");
            // let date = now.getFullYear() + "-" + now.getMonth()+1 + "-" + now.getDate();
            if (expirationDate > now) {
                return true;
            } else {
                // 
                // 
                // 
                return true
            }
        }
        else return false;
    }



    adminLogin(username: string, password: string) {
        return this.http.post<any>(`${environment.api_base}/public/cms/accounts/login`, { username, password })
            .pipe(map(loginData => {
                // login successful if there's a jwt token in the response
                if (loginData && loginData.success) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.sessionService.SaveUserData(loginData);
                    //localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(loginData);
                }
                return loginData;
            }));
    }

    isLoggedAdmin() {

        let userData = this.sessionService.GetUserData();
        if (userData === null || !userData.token) return false;

        // if (userData.role === ClaimRoles.SystemAdmin) return false;

        if (userData && userData.refreshToken.validUntil) { 
            var expirationDate = userData.refreshToken.validUntil; 
            var now = new Date("YYYY-MM-DDTHH:mm:ss.sssZ");
            // let date = now.getFullYear() + "-" + now.getMonth()+1 + "-" + now.getDate();
            if (expirationDate > now) {
                return true;
            } else {
                // 
                // 
                // 
                return true
            }
        }
        else return false;
        
        
        
        
        
        
        // get user data if exist
        // let userData = this.sessionService.GetUserData();
        // check the data and token
        if (userData === null || !userData.token) return false; 
        // confirm that logged user is system admin
        if (userData.role === ClaimRoles.SystemAdmin) {
            // validating the expiration date
            if (userData && userData.expirationDate) {
                let expirationDate = new Date(userData.expirationDate);
                let now = new Date(Date.now());
                if (expirationDate > now) return true;
            }

        }
        else return false;




        
    }

    userLogin(email: string, password: string) {
        return this.http.post<any>(`${environment.api_base}/public/accounts/login/user`, { email, password })
            .pipe(map(loginData => {
                // login successful if there's a jwt token in the response
                if (loginData && loginData.success) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.sessionService.SaveUserData(loginData);
                    // fire logging event
                    GlobalEventsService.UserLoggedIn.emit(loginData);
                    //localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(loginData);
                }
                return loginData;
            }));
    }

    coachLogin(email: string, password: string) {
        return this.http.post<any>(`${environment.api_base}/public/accounts/login/coach`, { email, password })
            .pipe(map(loginData => { 
                if (loginData && loginData.success) { 
                    this.sessionService.SaveUserData(loginData); 
                    GlobalEventsService.UserLoggedIn.emit(loginData); 
                    this.currentUserSubject.next(loginData);
                }
                return loginData;
            }));
    }

 

    authenticate = (payload: FormData) => this.http.post<any>(environment.baseUrl + "Account/authenticate", payload);
    
    
    updateUser = (req) => this.http.patch<any>( environment.baseUrl + "Account/update", req)

}
