import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-aboutus-page',
  templateUrl: './aboutus-page.component.html',
  styleUrls: ['./aboutus-page.component.css']
})
export class AboutusPageComponent implements OnInit {

  constructor(
    private _tranalationService: TranslationService,
    private titleService: Title,
    ) {}
  IsMobile: boolean=false;
  ngOnInit() {
    this.titleService.setTitle("About coach you | Coach you ")
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 996) {
      this.IsMobile = true
    }
    if (window.innerWidth >= 996) {
      this.IsMobile = false
    }
  }

  isArabic() {
    var lang = this._tranalationService.getLanguage();
    if (lang == 'ar')
      return true

    return false
  }

}
