import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-rate-box',
  templateUrl: './rate-box.component.html',
  styleUrls: ['./rate-box.component.css'],
  encapsulation : ViewEncapsulation.None
})
export class RateBoxComponent implements OnInit {

  @Input() rating: number;
  @Input() itemId: number;
  @Input() readOnly: boolean = false;
  @Input() showLabel: boolean = false;

  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();

  inputName: string;
  ngOnInit() {
    this.inputName = this.itemId + '_rating';
  }
  onClick(rating: number): void {
    if (this.readOnly) return;

    this.rating = rating;
    this.ratingClick.emit({
      itemId: this.itemId,
      rating: rating
    });
  }

}
